<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col class="text-center">
        <h1>
          {{ $t("notFound.title") }}
        </h1>
        <h4>
          <i18n path="notFound.home">
            <template v-slot:action>
              <router-link :to="{ name: 'Home' }" exact>{{
                $t("notFound.action")
              }}</router-link>
            </template>
          </i18n>
        </h4>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NotFound"
};
</script>
